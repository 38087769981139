@tailwind base;
@tailwind components;
@tailwind utilities;

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
